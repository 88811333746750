import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useMatch } from 'react-router-dom';
import { useLocalizeMessage, Tabs } from 'libs.nucleus';
import { PageHeader, PageWrapper, useTabNavigation } from 'libs.react';
import { ParticipantDetails } from '../../components/participant_details/participant_details.component';
import { ParticipantDocuments } from '../../components/participant_documents/participant_documents.component';
import { ParticipantVisits } from '../../components/participant_visits/participant_visits.component';
import { SiteContext } from '../../contexts/site';
import { getParticipantDetails } from '../../helpers/participants.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
export const ParticipantDetailsPage = () => {
    const translate = useLocalizeMessage();
    const { environment } = useCurrentEnvironment();
    const { site } = useContext(SiteContext);
    const match = useMatch('/site/study/:studyId/participants/:participantId');
    const participantId = match?.params.participantId;
    const [activeTab, setActiveTab] = useTabNavigation(['#details', '#visits', '#documents', '#audit-history']);
    const getParticipantData = async () => {
        if (!environment) {
            return {};
        }
        return await getParticipantDetails({
            environment,
            siteId: site?._id ?? '',
            participantId: participantId ?? '',
        });
    };
    const { data: participantData, isLoading } = useQuery({
        queryKey: ['participantDetails', participantId],
        queryFn: getParticipantData,
        enabled: true,
    });
    const breadcrumbsPages = [
        { name: translate('Participants'), path: `/site/study/${environment?.id}/participants` },
        { name: participantData?.c_number || translate('Participant Detail'), path: '' },
    ];
    return (_jsxs(PageWrapper, { children: [_jsx(PageHeader, { breadcrumbPages: breadcrumbsPages, title: participantData?.c_number || '' }), _jsx(Tabs, { tabLabels: [translate('Details'), translate('Visits'), translate('Documents'), translate('Audit History')], tabContent: [
                    _jsx(ParticipantDetails, { participant: participantData, participantDetailsLoading: isLoading }, 'participant-details'),
                    _jsx(ParticipantVisits, { participantNumber: participantData?.c_number || '' }, 'participant-visits'),
                    _jsx(ParticipantDocuments, { participantId: participantId }, 'participant-documents'),
                    _jsx("p", { children: "Participant Audit History go here" }, 'participant-audit-history'),
                ], defaultIndex: activeTab, onTabChange: setActiveTab })] }));
};
