import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';
import { AuthContext, useApiClient } from 'libs.react';
export const StudiesContext = createContext({
    isLoading: true,
    studies: [],
});
export const StudiesProvider = ({ children }) => {
    const { entityId, user } = useContext(AuthContext);
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const retrieveStudies = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/studies`);
        return response.data;
    };
    const { data: studies, isLoading } = useQuery({
        queryKey: ['entities', entityId, 'studies'],
        queryFn: retrieveStudies,
        enabled: !!user && !!entityId,
    });
    const value = useMemo(() => ({
        isLoading,
        studies: studies || [],
    }), [entityId, isLoading, studies]);
    return _jsx(StudiesContext.Provider, { value: value, children: children });
};
