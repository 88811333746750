import { jsx as _jsx } from "react/jsx-runtime";
import Papaparse from 'papaparse';
import { useMemo } from 'react';
import { Dropdown, useLocalizeMessage, ModalWindow, Spinner, Table } from 'libs.nucleus';
import { useToastNotification } from 'libs.react';
import { useTableInlineEdit } from '../../hooks/use_table_inline_edit';
export const TestPlanEditModal = ({ onClose, testPlan, onUpdatePlan }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { isEditMode, setTable, EditableTextField, EditableTextareaField, onRemoveRow, onInsertNewRow, onCloneRow, setIsEditMode, onCancel, onFinish, } = useTableInlineEdit();
    const isLoading = false;
    const columns = useMemo(() => {
        return [
            {
                header: translate('Test case number'),
                accessorKey: 'Test Case No',
                cell: EditableTextField,
            },
            {
                header: translate('Step type'),
                accessorKey: 'Step Type',
                cell: EditableTextField,
            },
            {
                header: translate('User'),
                accessorKey: 'User',
                cell: EditableTextField,
            },
            {
                header: translate('Context'),
                accessorKey: 'Context',
                cell: EditableTextareaField,
            },
            {
                header: translate('Task response'),
                accessorKey: 'Task Response',
                cell: EditableTextField,
            },
            {
                id: 'options',
                size: 20,
                cell: ({ row }) => {
                    if (!isEditMode) {
                        return null;
                    }
                    const dropdownItems = [
                        {
                            id: 'clone',
                            content: translate('Clone'),
                            onClick: () => onCloneRow(row.index),
                        },
                        {
                            id: 'delete',
                            content: translate('Delete'),
                            onClick: () => onRemoveRow(row),
                            destructive: true,
                        },
                    ];
                    return (_jsx("section", { className: 'flex flex-col items-end', children: _jsx(Dropdown, { position: 'bottom-end', items: dropdownItems, variant: 'kebab' }) }));
                },
            },
        ];
    }, [isEditMode, testPlan]);
    const handleOnCancel = () => {
        onCancel(testPlan);
    };
    const handleOnFinish = () => {
        onFinish((updatedData) => {
            onUpdatePlan(updatedData);
        });
    };
    const downloadTestAsCSV = () => {
        if (testPlan) {
            // use papa parse to convert test plan to CSV
            const csv = Papaparse.unparse(testPlan);
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `testplan.csv`;
            // download test plan as CSV
            document.body.appendChild(a);
            a.click();
        }
        else {
            addNotification({
                title: translate('Error downloading test plan'),
                subtitle: translate('No test plan available to download'),
                type: 'error',
            });
        }
    };
    const submitChangesButton = {
        label: translate('Save'),
        onClick: handleOnFinish,
    };
    const cancelModalButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const cancelChangesButton = {
        label: translate('Cancel'),
        onClick: handleOnCancel,
    };
    const addRowButton = {
        label: translate('Add row'),
        onClick: onInsertNewRow,
    };
    const enterEditModeButton = {
        label: translate('Edit'),
        onClick: () => setIsEditMode(true),
    };
    const downloadCsvButton = {
        label: translate('Download as CSV'),
        onClick: downloadTestAsCSV,
    };
    return (_jsx(ModalWindow, { title: translate('Test plan'), headerActionButton: isEditMode ? undefined : enterEditModeButton, isOpen: true, closeWindow: onClose, footerPrimaryActionButton: isEditMode ? submitChangesButton : downloadCsvButton, footerSecondaryActionButtons: isEditMode ? [addRowButton, cancelChangesButton] : [cancelModalButton], footerCenterContent: isLoading && _jsx(Spinner, { size: 'xl' }), width: 'full', children: _jsx(Table, { data: testPlan, columns: columns, labelNoResults: translate('No data'), isEditMode: isEditMode, hoistTableObject: setTable }) }));
};
