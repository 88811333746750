import { jsx as _jsx } from "react/jsx-runtime";
import { LocalizationStatus } from 'libs.localization.common';
import { Pill, Tooltip } from 'libs.nucleus';
import { toSentenceCase } from '../../utils/string_utils';
const statusColorMap = {
    [LocalizationStatus.READY]: 'greenPale',
    [LocalizationStatus.IN_PROGRESS]: 'orangePale',
    [LocalizationStatus.IN_REVIEW]: 'yellowPale',
    [LocalizationStatus.NOT_STARTED]: 'redPale',
};
export const AssetVersion = ({ currentVersion }) => {
    // Put into default loading state.
    if (!currentVersion || !currentVersion.status || (!currentVersion.version && !currentVersion.status)) {
        return (_jsx("div", { className: 'nucleus-flex nucleus-justify-center animate-pulse', children: _jsx(Pill, { label: '', backgroundColor: 'gray' }) }));
    }
    if (currentVersion.status === LocalizationStatus.NOT_AVAILABLE) {
        return '-';
    }
    const { status, version } = currentVersion;
    return (_jsx("div", { className: 'nucleus-flex nucleus-justify-center', children: _jsx(Tooltip, { placement: 'top', title: `${version > 0 ? `v${version}` : 'No version'}, ${toSentenceCase(status)}`, children: _jsx(Pill, { label: version > 0 ? `v${version}` : '', backgroundColor: statusColorMap[status], "data-testid": `locale-status-icon-${status.replace(' ', '-').toLowerCase()}` }) }) }));
};
