import { ONLY_POSITIVE_INTEGERS_REGEX } from '../../constants';
const isSameString = (str1, str2) => {
    return str1.trim().toLocaleLowerCase() === str2.trim().toLocaleLowerCase();
};
const sortBy = (items, key) => {
    items.sort((itemA, itemB) => {
        const valueA = itemA[key];
        const valueB = itemB[key];
        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB);
        }
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    });
    return items;
};
const isPositiveInputNumberValid = (value, maxValue, props = {}) => {
    return (!value ||
        (props.allowZero && Number(value) === 0) ||
        (ONLY_POSITIVE_INTEGERS_REGEX.test(value) && Number(value) <= maxValue && Number(value) > 0));
};
export const GenericUtils = {
    isPositiveInputNumberValid,
    isSameString,
    sortBy,
};
