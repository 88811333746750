import { ErrorCode } from '../../../types';
export const API_ERROR_MESSAGES = {
    [ErrorCode.ACCESS_DENIED_INVALID_TOKEN]: {
        title: 'Session Expired',
        subtitle: 'It seems like your session has expired or you have been signed out. Please sign in again to continue using the application.',
    },
    [ErrorCode.ACCESS_DENIED_REDEPLOYMENT]: {
        title: 'Error re-deploying study configuration',
        subtitle: 'The version you are trying to deploy requires deleting study configuration, deployment is not allowed, please contact Medable to request for assistance deploying this version.',
    },
    [ErrorCode.CONFLICT_INSTANCE_DELETE]: {
        title: 'Unable to delete study',
        subtitle: 'You are unable to delete a study with a sandbox or production environment. Please contact Medable for additional assistance.',
    },
    [ErrorCode.ENTITY_ACCESS_EXISTS]: {
        title: 'Duplicate email address',
        subtitle: 'The email provided already exists in this or other workspace. Email address must be unique across all Medable Workspaces. To resolve, use a different email address.',
    },
    [ErrorCode.IDENTITY_NOT_VERIFIED]: {
        title: 'Identity not verified',
        subtitle: 'Your account has not been verified. Please verify your account by clicking the link in the email sent to you.',
    },
    [ErrorCode.PROD_ENVIRONMENT_LIMIT_REACHED]: {
        title: 'Unable to create a new deployment',
        subtitle: 'You have reached the limit of environments in the selected region. Please select a different region or use the Update feature to update any of your existing environments.',
    },
};
export const ApiClientEndpointsByService = {
    ["iam" /* ApiClientService.IAM */]: process.env.IAM_SERVICE_ENDPOINT,
    ["library" /* ApiClientService.LIBRARY */]: process.env.LIBRARY_SERVICE_ENDPOINT,
    ["mom" /* ApiClientService.MOM */]: process.env.MOM_SERVICE_ENDPOINT,
    ["cloud" /* ApiClientService.CLOUD */]: process.env.CLOUD_SERVICE_ENDPOINT,
    ["web" /* ApiClientService.WEB */]: process.env.WEB_ENDPOINT,
    ["autobuild" /* ApiClientService.AUTOBUILD */]: process.env.AUTOBUILD_ENDPOINT,
};
