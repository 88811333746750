import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FlagContext } from 'libs.flags.react';
import { Button, useLocalizeMessage, useDialog, Pill, Spinner, Table, H3, Text } from 'libs.nucleus';
import { AuthContext, FormattedDateTime, ListInformation, PageHeader, useApiClient, useBooleanState, useDateTime, } from 'libs.react';
import { useStudyDeploymentsColumns } from './study_deployments_columns.hook';
import { STUDY_MANAGEMENT_FLAGS } from '../../constants';
import { StudyConfigurationContext } from '../../contexts';
import { useDeploymentUtils } from '../../hooks';
import { DeploymentUtils } from '../../utils/deployment';
import { EnvironmentStatus } from '../environment_status';
import { ServerTimeModal } from '../server_time_modal';
import { getUTCOffset } from '../server_time_modal/server_time_modal.utils';
export const StudyDeploymentDetails = ({ envId, originPage }) => {
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const { parseDateTimeShort } = useDateTime();
    const utcOffset = getUTCOffset();
    const { getRegionName } = useDeploymentUtils();
    const dialog = useDialog();
    const { entityId } = useContext(AuthContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const flagManager = useContext(FlagContext);
    const [isChangeServerTimeModalOpen, openChangeServerTimeModal, closeChangeServerTimeModal] = useBooleanState(false);
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const isNextReleaseFlagEnabled = flagManager.getValue(STUDY_MANAGEMENT_FLAGS.nextRelease);
    const showErrorDetails = async (workflow) => {
        const error = workflow.result?.faults?.[0];
        if (error) {
            await dialog.open({
                title: translate('Deployment details'),
                body: _jsx("pre", { className: 'text-text-secondary text-wrap', children: JSON.stringify(error, null, 2) }),
                width: 'lg',
                actionButtons: [{ label: translate('Close') }],
            });
        }
    };
    const deploymentsColumns = useStudyDeploymentsColumns(showErrorDetails);
    const retrieveStudyEnv = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/studies/${studyId}/environments/${envId}?withWorkflows=true`);
        return response.data;
    };
    const { data: studyEnv, isLoading, isError, } = useQuery({
        enabled: !!envId,
        queryFn: retrieveStudyEnv,
        queryKey: ['entities', entityId, 'studies', studyId, 'environment', envId],
    });
    const retrieveServerTime = async () => {
        if (!studyEnv || !DeploymentUtils.isTimeTravelEnvironment(studyEnv)) {
            return;
        }
        const cortexClusterUrl = studyEnv.cortexURL;
        const { data: serverTime } = await axios.get(`${cortexClusterUrl}/settime/`);
        return serverTime.date;
    };
    const { data: currentServerDateTime } = useQuery({
        enabled: !!studyEnv,
        queryFn: retrieveServerTime,
        queryKey: ['entities', entityId, 'studies', studyId, 'environment', envId, 'serverTime'],
    });
    const isTimeTravel = studyEnv && DeploymentUtils.isTimeTravelEnvironment(studyEnv);
    const deploymentStatus = studyEnv && DeploymentUtils.getEnvironmentStatus(studyEnv);
    const listItems = useMemo(() => {
        if (!studyEnv) {
            return [];
        }
        const workflow = DeploymentUtils.getEnvironmentWorkflow(studyEnv);
        const shouldShowServerTime = isTimeTravel && currentServerDateTime && deploymentStatus === 'completed';
        const buildPackage = studyEnv.metadata.packages.find((pkg) => pkg.type === 'studyConfig');
        return [
            { title: translate('Description'), description: studyEnv.metadata.description },
            {
                title: translate('Status'),
                description: _jsx(EnvironmentStatus, { environment: studyEnv }),
                actionContent: workflow.result?.faults && (_jsx(Button, { type: 'button', variant: 'transparent', label: translate('View details'), onClick: () => showErrorDetails(workflow) })),
            },
            {
                title: translate('Type'),
                description: isTimeTravel ? translate('Time travel') : translate('Standard'),
            },
            {
                title: translate('Server time'),
                description: (_jsxs(Text, { size: 'sm', children: [_jsx(FormattedDateTime, { date: currentServerDateTime }), " ", translate('(UTC{offset})', { offset: utcOffset })] })),
                actionContent: (_jsx(Button, { variant: 'transparent', label: translate('Change server time'), onClick: openChangeServerTimeModal })),
                hidden: !shouldShowServerTime,
            },
            {
                title: translate('Build version'),
                description: isNextReleaseFlagEnabled ? workflow.studyConfigVersion : buildPackage?.version,
            },
            { title: translate('Owner'), description: studyEnv.owner.email },
            { title: translate('Date created'), description: parseDateTimeShort(studyEnv.createdAt) },
            { title: translate('Last updated'), description: parseDateTimeShort(workflow.updatedAt) },
            { title: translate('Internal name'), description: studyEnv.code },
        ];
    }, [studyEnv, currentServerDateTime, deploymentStatus]);
    const launchEnv = () => {
        if (studyEnv) {
            const url = DeploymentUtils.buildEnvironmentUrl(studyEnv);
            logEvent('launch_env_sandbox', { study_id: studyId, env_name: studyEnv.name });
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };
    const breadcrumbsPages = [originPage, { name: studyEnv?.metadata.name || '', path: '' }];
    const headerOptions = deploymentStatus === 'completed' ? [{ name: translate('Launch'), onClick: launchEnv, variant: 'primary' }] : [];
    if (isError) {
        navigate(`/studies/${studyId}/tests`);
    }
    const TitleElement = (_jsxs("div", { className: 'flex items-center gap-4', children: [_jsx(H3, { className: 'text-4xl font-light text-text-primary', children: studyEnv?.name || '' }), studyEnv && (_jsx("div", { className: 'pt-1', children: _jsx(Pill, { label: getRegionName(studyEnv.region), backgroundColor: 'cyan' }) }))] }));
    return (_jsxs("section", { className: 'relative p-6 h-full flex flex-col', children: [_jsx(PageHeader, { breadcrumbPages: breadcrumbsPages, title: TitleElement, options: headerOptions }), _jsxs("div", { className: 'flex-1 overflow-auto relative', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx(ListInformation, { items: listItems }), !isLoading && isNextReleaseFlagEnabled && (_jsxs("div", { className: 'mt-6', children: [_jsx(Text, { children: translate('Deployment history') }), _jsx(Text, { size: 'sm', className: 'mb-3 text-text-secondary', children: translate('This is the record of all times you have deployed versions of your study build to this sandbox.') }), _jsx(Table, { columns: deploymentsColumns, data: studyEnv?.workflows || [], hasPagination: true, initialPageSize: 10, paginationNavigationScreenReaderLabel: translate('Deployments table navigation') })] }))] }), isTimeTravel && isChangeServerTimeModalOpen && (_jsx(ServerTimeModal, { serverDateTime: currentServerDateTime, studyEnv: studyEnv, onClose: closeChangeServerTimeModal }))] }));
};
