import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from '@tanstack/react-query';
import Papaparse from 'papaparse';
import { useContext, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FormSections, useLocalizeMessage, Input, ModalWindow, Select, Spinner, TextArea, } from 'libs.nucleus';
import { AuthContext, LibraryEndpoint, useApiClient, useToastNotification, } from 'libs.react';
import { StudyConfigurationContext } from '../../contexts';
import { sanitizeStudy } from '../../utils';
export const GenerateTestPlanModal = ({ onClose, onSave, tests }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { entityId } = useContext(AuthContext);
    const { studyId, currentStudy } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [cortexToken, setCortexToken] = useState('');
    const [studyTestForm, setStudyTestForm] = useState({
        name: '',
        description: '',
        sandbox: { label: '-select-', value: '', meta: {} },
    });
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const autobuildClient = useApiClient("autobuild" /* ApiClientService.AUTOBUILD */);
    const libClient = useApiClient("library" /* ApiClientService.LIBRARY */);
    const saveDisabled = useMemo(() => {
        return !studyTestForm.name || !studyTestForm.sandbox.value || isLoading;
    }, [studyTestForm, isLoading]);
    const testOptions = useMemo(() => tests.map((test) => ({
        label: translate(`${test.metadata.name}`),
        value: test.id.toString(),
        meta: {
            package: test.metadata.packages.find((pkg) => pkg.name === currentStudy.name),
        },
    })), [tests]);
    const envId = studyTestForm.sandbox.value;
    const getToken = async () => {
        const { data } = await momClient.post(`/v1/entities/${entityId}/studies/${studyId}/environments/${envId}/cortexToken`);
        return data.data;
    };
    const { mutateAsync: getCortexToken } = useMutation({
        mutationKey: ['entities', entityId, 'studies', studyId, 'environment', envId],
        mutationFn: getToken,
        onSuccess: (token) => {
            if (token) {
                setCortexToken(token);
                logEvent('get_cortex_token', { entity_id: entityId, study_id: studyId, env_id: envId });
            }
        },
    });
    const saveTest = async () => {
        if (!saveDisabled) {
            try {
                setIsLoading(true);
                const [{ data: studyConfigResponse }, token] = await Promise.all([
                    libClient.get(LibraryEndpoint.GET_STUDY_CONFIGS, {
                        params: { id: studyTestForm.sandbox.meta?.resourceId },
                    }),
                    cortexToken ? Promise.resolve(cortexToken) : getCortexToken(),
                ]);
                const study = sanitizeStudy(studyConfigResponse.data[0].data);
                const earlyWithdrawalVisits = new Set(Object.values(study.getAllVisits())
                    .filter((visit) => visit.visitType === 'end_of_study')
                    .map((visit) => visit.name));
                const { data: { soaGraph }, } = await autobuildClient.post('/study-summary/generate', { token });
                const testPlanCriteria = {
                    skipGroups: [...earlyWithdrawalVisits],
                    skipTaskAvailabilityAssertions: true,
                    users: [
                        {
                            Role: 'Axon Site User',
                            User: 'SiteUser1',
                            Email: 'siteuser1@medable.com',
                        },
                        {
                            User: 'Participant1',
                            Email: 'participant1@medable.com',
                        },
                    ],
                };
                const { data: csv } = await autobuildClient.post('/testplan/generate', { soaGraph, testPlanCriteria });
                const testPlan = Papaparse.parse(csv, {
                    header: true,
                    skipEmptyLines: true,
                }).data;
                const { data: response } = await libClient.post(`${LibraryEndpoint.GET_TEST_PLANS}`, {
                    name: studyTestForm.name,
                    key: uuidv4(),
                    data: {
                        description: studyTestForm.description,
                        csv: testPlan,
                    },
                    metadata: {
                        name: studyTestForm.name,
                        sandboxId: Number(studyTestForm.sandbox.value),
                        sandboxName: studyTestForm.sandbox.label,
                    },
                    studyId,
                });
                onSave(response.data);
            }
            catch (error) {
                console.error('Error deploying study configuration', error);
                onDeployError();
            }
        }
    };
    const onDeployError = (message) => {
        const subtitle = message
            ? translate('The deployment of this version of your study failed because of "{message}"', { message })
            : translate('The deployment of this version of your study failed. Please try again.');
        addNotification({ title: translate('Error deploying study'), subtitle, type: 'error' });
        setIsLoading(false);
    };
    const savePrimaryButton = {
        label: translate('Generate'),
        onClick: saveTest,
        disabled: saveDisabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            saveTest();
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        saveTest();
    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setStudyTestForm((oldForm) => ({ ...oldForm, [id]: value }));
    };
    const handleTestChange = (selectedTest) => {
        setStudyTestForm((oldForm) => ({
            ...oldForm,
            sandbox: {
                ...selectedTest,
                meta: selectedTest.meta || { package: undefined },
            },
            name: selectedTest.label,
        }));
    };
    const formSections = [
        {
            title: translate('Test plan details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(Input, { id: 'name', label: translate('Name'), maxLength: 50, onChange: handleInputChange, required: true, value: studyTestForm.name, width: 'lg', autoComplete: 'off' }), _jsx(TextArea, { id: 'description', label: translate('Description'), onChange: handleInputChange, value: studyTestForm.description, width: 'lg' })] })),
        },
        {
            title: translate('Sandbox'),
            content: (_jsx("div", { className: 'flex flex-col gap-6 w-[27.5rem]', children: _jsx(Select, { dataTestId: 'select-sandbox', label: translate('Select sandbox'), description: translate('Select the sandbox to generate a test plan'), onChange: handleTestChange, options: testOptions, required: true, value: studyTestForm.sandbox, width: 'lg' }) })),
        },
    ];
    return (_jsxs(ModalWindow, { title: translate('Generate default test plan'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) })] }));
};
