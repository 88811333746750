export const TRANSLATION_MANAGEMENT_FLAGS = {
    aiTranslationFlag: {
        id: 'aiTranslation',
        value: false,
        description: 'Enables instant translation using generative AI',
        owner: 'Translation Team',
        name: 'Generate Translation (AI)',
        extra: { section: 'Translation' },
    },
};
