import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { EM_DASH } from 'libs.constants';
import { Pill } from 'libs.nucleus';
import { getApiInstance } from './api.helpers';
import { PARTICIPANT_NUMBER_PREFIX } from './constants.helpers';
import { normalizeDate } from './date.helpers';
import { ParticipantStatuses } from '../constants/participants.constants';
export const getParticipantNumber = (rowData) => {
    if (rowData.c_number) {
        return rowData.c_number;
    }
    const { _id } = rowData;
    return `${PARTICIPANT_NUMBER_PREFIX}-${_id.substring(_id.length - 6, _id.length)}`;
};
export const getParticipantStatus = (status) => {
    if (status === ParticipantStatuses.CONSENTED) {
        return _jsx(Pill, { label: status, iconBackgroundColor: 'green', variant: 'status' });
    }
    return _jsx(Pill, { label: status, iconBackgroundColor: 'gray', variant: 'status' });
};
export const getParticipantDetails = async ({ environment, siteId, participantId, }) => {
    try {
        const response = await getApiInstance(environment).get(`/v2/routes/site_subjects/${siteId}`, {
            params: {
                expand: ['c_visit_schedule'],
                where: JSON.stringify({ _id: participantId }),
            },
        });
        return response?.data?.data[0] || {};
    }
    catch (error) {
        return {};
    }
};
export const isParticipantDeactivated = (participant) => participant?.c_status?.toLowerCase() === 'deactivated';
export const getParticipantNextVisitWindow = (participant) => {
    // todo: add localization for date format
    if (participant.c_next_visit_window_start && participant.c_next_visit_window_end) {
        const startDateFormatted = moment(participant.c_next_visit_window_start).format('YYYY-MMM-DD');
        const endDateFormatted = moment(participant.c_next_visit_window_end).format('YYYY-MMM-DD');
        return `${startDateFormatted} to ${endDateFormatted}`;
    }
    if (participant.c_next_visit_date) {
        return moment(participant.c_next_visit_date).format('YYYY-MMM-DD');
    }
    return EM_DASH;
};
export const formatVisitWindow = (param) => {
    if (!param.windowStartDate || !param.windowEndDate) {
        return EM_DASH;
    }
    const startDate = normalizeDate(param.windowStartDate);
    const endDate = normalizeDate(param.windowEndDate);
    const startMonthName = new Intl.DateTimeFormat(param.currentLocale, { month: 'long' }).format(startDate);
    const endMonthName = new Intl.DateTimeFormat(param.currentLocale, { month: 'long' }).format(endDate);
    const year = startDate.getFullYear();
    // Check if the months are the same
    let periodStr;
    if (startMonthName === endMonthName) {
        const period = startDate.getDate() === endDate.getDate() ? startDate.getDate() : `${startDate.getDate()}-${endDate.getDate()}`;
        periodStr = `${startMonthName} ${period}, ${year}`;
    }
    else {
        periodStr = `${startMonthName} ${startDate.getDate()} - ${endMonthName} ${endDate.getDate()}, ${year}`;
    }
    return periodStr;
};
export const getParticipantDuplicateIndexErrorMessage = (error, translate) => {
    const errorData = error?.response?.data;
    if (['kError'].includes(errorData?.code) &&
        [errorData?.faults?.[0]?.code, errorData?.faults?.[0]?.faults?.[0]?.code].includes('kValidationError') &&
        [errorData?.faults?.[0]?.faults?.[0]?.errCode, errorData?.faults?.[0]?.faults?.[0]?.faults?.[0]?.errCode].includes('cortex.conflict.duplicateKey')) {
        let message;
        const errorPath = errorData?.faults?.[0]?.faults?.[0]?.path || errorData?.faults?.[0]?.faults?.[0]?.faults?.[0]?.path;
        switch (errorPath) {
            case 'c_number':
                message = translate('Participant ID already exists');
                break;
            case 'c_email':
                message = translate('Participant email already exists');
                break;
        }
        return message;
    }
};
