import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FlagContext } from 'libs.flags.react';
import { useLocalizeMessage, InlineNotification, Toggle } from 'libs.nucleus';
import { ListInformation } from 'libs.react';
import { TranslationStartedNotification } from 'libs.translation_workbench';
import { StudyConfigurationContext } from '../../../contexts';
export const StudySettings = () => {
    const translate = useLocalizeMessage();
    const flagManager = useContext(FlagContext);
    const { assignStudy, currentStudy, isReadOnlyMode, studyId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const updateStudyMetadata = (partialMetadata) => {
        currentStudy.updateStudyMetadata(partialMetadata);
        assignStudy(currentStudy);
    };
    const onIsEConsentEnabledChange = (isEConsentEnabled) => {
        updateStudyMetadata({ isEConsentEnabled });
        logEvent('enable_econsent_toggled', { study_id: studyId, is_econsent_enabled: isEConsentEnabled });
    };
    const onIsTelevisitEnabledChange = (isTelevisitEnabled) => {
        updateStudyMetadata({ isTelevisitEnabled });
        logEvent('enable_televisit_toggled', { study_id: studyId, is_televisit_enabled: isTelevisitEnabled });
    };
    const content = useMemo(() => {
        return [
            {
                title: translate('Enable eConsent'),
                description: translate('If turned on, the consent manager tab will be displayed in Site App'),
                actionContent: (_jsx(Toggle, { checked: currentStudy.isEConsentEnabled, dataTestId: 'enable-econsent-toggle', disabled: isReadOnlyMode || currentStudy.isNoPIIEnabled, onChange: onIsEConsentEnabledChange })),
            },
            {
                title: translate('Adhoc televisits'),
                description: translate('Enabling this will allow televisit to be used adhoc at any time for sites and participants throughout the study'),
                actionContent: (_jsx(Toggle, { checked: currentStudy.isTelevisitEnabled, dataTestId: 'enable-televisit-toggle', disabled: isReadOnlyMode, onChange: onIsTelevisitEnabledChange })),
            },
        ];
    }, [flagManager, onIsEConsentEnabledChange, currentStudy.isEConsentEnabled, currentStudy.isTelevisitEnabled]);
    return (_jsxs("section", { className: 'flex flex-col gap-5', children: [isReadOnlyMode && _jsx(TranslationStartedNotification, {}), currentStudy.isNoPIIEnabled && !isReadOnlyMode && (_jsx(InlineNotification, { type: 'warning', role: 'status', title: translate('Warning'), subtitle: translate('Your participant registration method is set to "Username". To enable eConsent, please change your registration method to "Email"') })), _jsx(ListInformation, { items: content, descriptionColor: 'secondary' })] }));
};
