import { ParagraphNode, isHTMLElement, } from 'lexical';
import { textClasses } from '../utils';
export class CustomParagraphNode extends ParagraphNode {
    extraClasses;
    constructor(extraClasses, key) {
        super(key);
        this.extraClasses = extraClasses;
    }
    static getType() {
        return 'custom_paragraph';
    }
    static clone(node) {
        if (node instanceof CustomParagraphNode) {
            return new CustomParagraphNode(node.extraClasses, node.getKey());
        }
        return new CustomParagraphNode('', node.getKey());
    }
    static importJSON(node) {
        return super.importJSON(node);
    }
    createDOM(config) {
        const dom = super.createDOM(config);
        dom.setAttribute('class', this.extraClasses ? textClasses + ' ' + this.extraClasses : textClasses);
        return dom;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            type: 'custom_paragraph',
            version: 1,
            textFormat: this.getTextFormat(),
            textStyle: this.getTextStyle(),
        };
    }
    /**
     * Overrides the exportDOM method to remove additional classes, styles, and attributes from a p tag.
     * @override
     */
    exportDOM(editor) {
        const { element } = super.exportDOM(editor);
        if (element && isHTMLElement(element)) {
            // TODO: The right thing to do here would be to only remove the texClasses and extraClasses added in createDOM.
            element.removeAttribute('class');
        }
        return { element };
    }
}
