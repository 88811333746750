import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useStudyL10nMap } from 'libs.localization.react';
import { TranslationWorkbenchContext } from '../../contexts';
import { LocaleStatusProgress } from '.';
export const StudyLocaleStatusProgress = (props) => {
    const { studyId } = useParams();
    const { setStudyL10nMap } = useStudyL10nMap();
    const { study, updateStudy } = useContext(TranslationWorkbenchContext);
    const handler = useCallback((props) => {
        if (!studyId) {
            console.error(new Error('Internal error'));
            return;
        }
        const { id, locale, version } = props;
        const currentL10nMapId = study.l10nMapResourceId;
        setStudyL10nMap({ studyId: parseInt(studyId, 10), currentL10nMapId, assetLocaleVersionSet: [{ id, locale, version }] }, {
            onSuccess: (response) => {
                if (response?.data?.id) {
                    study.l10nMapResourceId = response.data.id;
                    updateStudy?.(study);
                }
            },
        });
    }, [studyId, study.l10nMapResourceId, updateStudy]);
    if (!studyId) {
        return null;
    }
    return _jsx(LocaleStatusProgress, { ...props, onStatusChange: handler });
};
