import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SDKStudy, StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { useL10nMapFetch, useL10nMapSync, useLibraryData, useStudyStringsData } from '../hooks';
import { useNotificationsData } from '../hooks/use_notifications_data.hook';
import { useResourcesData } from '../hooks/use_resources_data.hook';
import { useResourcesPdfData } from '../hooks/use_resourcespdf_data.hook';
export const TranslationWorkbenchContext = createContext({
    locales: [],
    siteLocales: [],
    participantLocales: [],
    isStudyEnglishOnly: false,
    resourcesAssets: {},
    libraryAssets: {},
    studyAssets: {},
    notificationsAssets: {},
    studyId: '',
    studyLocalizationStatus: StudyLocalizationStatus.NOT_STARTED,
    study: new SDKStudy({ name: '' }),
    isAssetsDataLoading: false,
});
export const TranslationWorkbenchProvider = ({ children, study, updateStudy, }) => {
    const { studyId } = useParams();
    const { localizationStatus: studyLocalizationStatus = StudyLocalizationStatus.NOT_STARTED } = study || {};
    const locales = Array.from(new Set([...study.participantLocales, ...study.siteLocales]));
    const isStudyEnglishOnly = study.enforceBaseLocale;
    const [l10nMap, setL10nMap] = useState();
    const { data: l10nMapData } = useL10nMapFetch(study.l10nMapResourceId);
    useEffect(() => {
        if (l10nMapData) {
            setL10nMap(l10nMapData);
        }
    }, [l10nMapData]);
    const { data: libraryAssets, l10SyncObjects, } = useLibraryData(study.getAllActivities(), study.participantLocales, study.siteLocales, l10nMap);
    const { data: resourcesAssets } = useResourcesData(study, study.participantLocales, study.siteLocales);
    const { data: resourcesPdfAssets } = useResourcesPdfData(study, study.participantLocales, study.siteLocales);
    const { data: studyAssets } = useStudyStringsData(study, study.participantLocales, study.siteLocales);
    const { data: notificationsAssets } = useNotificationsData(studyId, study.participantLocales, study.siteLocales);
    const { isPending: isL10nMapSyncPending } = useL10nMapSync({
        l10SyncObjects,
        l10nMapResourceId: study.l10nMapResourceId,
        study,
        updateStudy,
    });
    const getAssetByTranslationWorkbenchId = (translationWorkbenchId) => {
        if (translationWorkbenchId) {
            return Object.values({ ...libraryAssets, ...studyAssets, ...resourcesAssets, ...notificationsAssets }).find((asset) => asset.translationWorkbenchId === translationWorkbenchId);
        }
    };
    const isAssetsDataLoading = isL10nMapSyncPending;
    // @TODO: Disabling the rest for now in favor of cell wise loading
    // isResourcesDataLoading ||
    // isResourcesPDFDataLoading ||
    // isLibraryDataLoading ||
    // isStudyStringsDataLoading ||
    // isNotificationsDataLoading ||
    // isL10nMapSyncPending;
    return (_jsx(TranslationWorkbenchContext.Provider, { value: {
            locales,
            siteLocales: study.siteLocales,
            participantLocales: study.participantLocales,
            isStudyEnglishOnly,
            resourcesAssets: { ...resourcesAssets, ...resourcesPdfAssets },
            libraryAssets,
            studyAssets,
            notificationsAssets,
            studyId: study.id,
            studyLocalizationStatus,
            study,
            updateStudy,
            l10nMap,
            getAssetByTranslationWorkbenchId,
            isAssetsDataLoading,
            setL10nMap,
        }, children: children }));
};
