import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { I18nUtils } from '@medable/i18n-core';
import { LocaleContext, useLocalizeMessage, Link, Spinner, Text } from 'libs.nucleus';
import { ListInformation, useBooleanState, useDateTime } from 'libs.react';
import { getParticipantNextVisitWindow, isParticipantDeactivated } from '../../helpers/participants.helpers';
import { getStudyShortCode } from '../../helpers/study.helpers';
import { useCurrentEnvironment } from '../../hooks/use_current_environment';
import { ParticipantDeactivationModal } from '../participant_deactivation/participant_deactivation_modal.component';
import { ParticipantInviteModal } from '../participant_form/participant_invite_modal.component';
export const ParticipantDetails = ({ participant, participantDetailsLoading }) => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { parseDateTimeShort } = useDateTime();
    const [isInviteModalOpen, setIsInviteModalOpen, setIsInviteModalClose] = useBooleanState(false);
    const [isDeactivationModalOpen, setIsDeactivationModalOpen, setIsDeactivationModalClosed] = useBooleanState(false);
    const { environment } = useCurrentEnvironment();
    const queryClient = useQueryClient();
    const getStudyCode = async () => {
        return await getStudyShortCode(window.location.hostname, environment?.cortexURL ?? '', environment?.code ?? '');
    };
    const { data: studyCode } = useQuery({
        queryKey: ['getStudyCode', environment?.code],
        queryFn: getStudyCode,
        enabled: !queryClient.getQueryData(['getStudyCode', environment?.code]),
    });
    if (!participant || participantDetailsLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    const detailItems = [
        {
            title: translate('Language'),
            description: participant.c_locale ? I18nUtils.getLanguageLabel(participant.c_locale, currentLocale, false) : null,
        },
        {
            title: translate('Enrolled'),
            description: participant.c_enrollment_date && parseDateTimeShort(participant.c_enrollment_date),
        },
        { title: translate('Email'), description: participant.c_email },
        {
            title: translate('Invite Code'),
            description: (_jsxs("div", { children: [_jsxs(Text, { size: 'sm', className: 'text-text-primary', children: [translate('Study code'), ": ", studyCode] }), _jsxs(Text, { size: 'sm', className: 'text-text-primary', children: [translate('Invite code'), ": ", participant.c_access_code] })] })),
            actionContent: (_jsx(Link, { "data-testid": 'add-view-invite', href: '#', onClick: setIsInviteModalOpen, children: translate('View') })),
        },
        { title: translate('Cohort'), description: participant.c_visit_schedule?.c_name },
        {
            title: translate('Status'),
            description: participant.c_status,
        },
        { title: translate('Next Visit'), description: getParticipantNextVisitWindow(participant) },
        {
            title: translate('Created'),
            description: parseDateTimeShort(participant.created),
            //  If participant doesn't have an account or is deactivated, don't show the deactivate button
            ...(participant?.c_account &&
                !isParticipantDeactivated(participant) && {
                actionContent: (_jsx(Link, { "data-testid": 'add-view-invite', href: '#', onClick: setIsDeactivationModalOpen, children: translate('Deactivate') })),
            }),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ParticipantInviteModal, { isModalOpen: isInviteModalOpen, participant: participant, onClose: setIsInviteModalClose }), _jsx(ParticipantDeactivationModal, { isModalOpen: isDeactivationModalOpen, participant: participant, onClose: setIsDeactivationModalClosed, onDone: () => {
                    queryClient.invalidateQueries({
                        queryKey: ['participantDetails', participant._id],
                    });
                } }), _jsx("div", { className: 'flex flex-col gap-8', children: _jsx(ListInformation, { dataTestId: 'participant-details-list-information', items: detailItems, useDefaultEmptyDescription: true }) })] }));
};
