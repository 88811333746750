export const NO_ACTIONS_SCREENS = ['review', 'completion'];
export const ScreenTypes = {
    instruction: 'Instruction',
    vrs: 'VRS',
    nrs: 'NRS',
    vas: 'VAS',
    nde: 'NDE',
    date_time: 'Date/Time Entry',
    eqvas: 'EQVAS',
    text_input: 'Text Input',
};
export var UrlScreenModalActions;
(function (UrlScreenModalActions) {
    UrlScreenModalActions["VIEW"] = "view";
    UrlScreenModalActions["EDIT"] = "edit";
    UrlScreenModalActions["ADD"] = "add";
    UrlScreenModalActions["BRANCHING"] = "branching";
    UrlScreenModalActions["BRANCHING_EDIT"] = "branching_edit";
    UrlScreenModalActions["CDASH"] = "cdash";
    UrlScreenModalActions["CDASH_EDIT"] = "cdash_edit";
})(UrlScreenModalActions || (UrlScreenModalActions = {}));
