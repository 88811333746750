import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { FirebaseAuthContext } from 'libs.firebase_react';
import { useApiClient } from 'libs.react';
export const useLocalizedStatusChange = () => {
    const { post } = useApiClient("web" /* ApiClientService.WEB */);
    const { firebaseToken } = useContext(FirebaseAuthContext);
    return useMutation({
        mutationKey: ['translation', 'localization_status'],
        mutationFn: ({ locale, asset, status }) => {
            return post('/api/translation/localization_status', { locale, asset, status }, {
                headers: {
                    Authorization: `Bearer ${firebaseToken}`,
                },
                withCredentials: true,
            });
        },
        retry: false,
    });
};
