import { jsx as _jsx } from "react/jsx-runtime";
import { AxiosError } from 'axios';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useLocalizeMessage, Spinner } from 'libs.nucleus';
import { useToastNotification } from '../../../hooks';
import { useApiClient } from '../../api_client';
import { AuthEndpoint, AuthRoute } from '../types';
const isMedableIdentity = (identity) => {
    return identity.email.endsWith('@medable.com');
};
const missingAuthProvider = () => {
    throw Error('Missing the auth provider');
};
export const AuthContext = createContext({
    entity: undefined,
    entityId: 0,
    error: undefined,
    forgotPassword: missingAuthProvider,
    getIDPConfiguration: missingAuthProvider,
    isInternalUser: false,
    isLoading: true,
    isLoggedIn: false,
    login: missingAuthProvider,
    logout: missingAuthProvider,
    navigateToLogin: missingAuthProvider,
    refreshIdentity: missingAuthProvider,
    resetPassword: missingAuthProvider,
    scopes: {},
    setError: () => undefined,
    ssoLogin: missingAuthProvider,
    user: undefined,
});
const iamServiceEndPoint = process.env.IAM_SERVICE_ENDPOINT;
/**
 * The AuthProvider is the main context provider to handle the user information and authentication
 * It communicates with the IAM service to validate the user credentials and store the token
 */
export const AuthProvider = ({ children }) => {
    const iamClient = useApiClient("iam" /* ApiClientService.IAM */);
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState();
    const [user, setUser] = useState();
    const [scopes, setScopes] = useState({});
    const [isInternalUser, setIsInternalUser] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [entityId, setEntityId] = useState(0);
    const [entity, setEntity] = useState();
    useEffect(() => {
        refreshIdentity();
    }, []);
    useEffect(() => {
        const errorDescription = searchParams.get('error_description');
        if (errorDescription) {
            addNotification({
                title: translate('Error'),
                subtitle: errorDescription,
                type: 'error',
            });
            searchParams.delete('error_description');
            setSearchParams(searchParams);
        }
        if (searchParams.get('expired-session') === 'true') {
            cleanUserInformation();
            searchParams.delete('expired-session');
            setSearchParams(searchParams);
        }
    }, [searchParams]);
    const refreshIdentity = async () => {
        setIsLoading(true);
        try {
            const { data } = await iamClient.get(AuthEndpoint.ME);
            await getEntityId(data.identity);
            if (data && data.identity) {
                setIsInternalUser(isMedableIdentity(data.identity));
                setIsLoggedIn(true);
                setUser(data.identity);
                setScopes(data.scopes);
            }
            else {
                cleanUserInformation();
            }
        }
        catch {
            cleanUserInformation();
        }
        finally {
            setIsLoading(false);
            setIsInitialized(true);
        }
    };
    const getEntity = async (entityId) => {
        try {
            const { data: response } = await momClient.get(`/v1/entities/${entityId}`);
            setEntity(response.data);
        }
        catch (error) {
            setEntity(undefined);
        }
    };
    const getEntityId = async (identity) => {
        if (!identity.entityId) {
            try {
                const { data: response } = await momClient.get('/v1/entities');
                if (response.data.length > 0) {
                    setEntityId(response.data[0].id);
                    setEntity(response.data[0]);
                }
            }
            catch (error) {
                setEntityId(0);
            }
        }
        else {
            setEntityId(identity.entityId);
            getEntity(identity.entityId);
        }
    };
    /**
     * Checks the IDPs associated to the given email and returns those
     */
    const getIDPConfiguration = async (email) => {
        setIsLoading(true);
        try {
            const searchParams = new URLSearchParams({ email });
            const { data: response } = await iamClient.get(`/v1/sso/check-username?${searchParams.toString()}`);
            setIsLoading(false);
            return response.data;
        }
        catch (error) {
            setIsLoading(false);
        }
        return [];
    };
    const cleanUserInformation = () => {
        setUser(undefined);
        setIsInternalUser(false);
        setIsLoggedIn(false);
        setEntityId(0);
        setScopes({});
    };
    /**
     * Used to log the user in the application and set the token and user identity information
     */
    const login = async (email, password) => {
        setIsLoading(true);
        try {
            const { data } = await iamClient.post(AuthEndpoint.LOGIN, { email, password });
            await getEntityId(data.identity);
            setIsInternalUser(isMedableIdentity(data.identity));
            setIsLoggedIn(true);
            setUser(data.identity);
            setScopes(data.scopes);
        }
        catch (err) {
            if (err instanceof AxiosError) {
                setError(err.response?.data.error);
            }
            throw err;
        }
        finally {
            setIsLoading(false);
        }
    };
    /**
     * Removes any stored user identity information and calls to invalidate the token on the IAM service
     */
    const logout = async () => {
        cleanUserInformation();
        await iamClient.post(AuthEndpoint.LOGOUT);
    };
    /**
     * Triggers the forgot password flow, that will send an email to the user with a link to reset the password
     */
    const forgotPassword = async (email) => {
        setIsLoading(true);
        try {
            await iamClient.post(AuthEndpoint.FORGOT_PASSWORD, { email });
        }
        catch (err) {
            if (err instanceof AxiosError) {
                setError(err.response?.data.error);
            }
            throw err;
        }
        finally {
            setIsLoading(false);
        }
    };
    /**
     * Triggers the SSO login flow, allowing the user to login using some configured IDP service
     */
    const ssoLogin = (idpConfiguration, email) => {
        setIsLoading(true);
        const url = `${iamServiceEndPoint}${AuthEndpoint.SSO_LOGIN}?`;
        const newSearchParams = new URLSearchParams({
            idp: idpConfiguration.key,
            return_to: `${window.location.origin}${searchParams.get('redirect') || '/'}`,
            email,
        });
        document.location.href = `${url}${newSearchParams.toString()}`;
    };
    /**
     * Used to reset the user password, normally used when the user has received an email to reset the password
     * either if it is to reset the password or to set the password by the first time
     */
    const resetPassword = async (password, token) => {
        setIsLoading(true);
        try {
            await iamClient.post(`${AuthEndpoint.RESET_PASSWORD}${token}`, { password });
            addNotification({
                title: translate('Reset password'),
                subtitle: translate('Your new password has been set. You may now sign in.'),
                type: 'success',
            });
        }
        catch (err) {
            if (err instanceof AxiosError) {
                setError(err.response?.data.error);
            }
            throw err;
        }
        finally {
            setIsLoading(false);
        }
    };
    const navigateToLogin = () => {
        if (pathname !== AuthRoute.LOGIN && pathname !== '') {
            navigate(`${AuthRoute.LOGIN}?redirect=${pathname}`);
        }
    };
    const providerValue = useMemo(() => ({
        entity,
        entityId,
        error,
        forgotPassword,
        getIDPConfiguration,
        isInternalUser,
        isLoading,
        isLoggedIn,
        login,
        logout,
        navigateToLogin,
        refreshIdentity,
        resetPassword,
        scopes,
        setError,
        ssoLogin,
        user,
    }), [
        entity,
        entityId,
        error,
        forgotPassword,
        getIDPConfiguration,
        isInternalUser,
        isLoading,
        isLoggedIn,
        login,
        logout,
        navigateToLogin,
        refreshIdentity,
        resetPassword,
        scopes,
        setError,
        ssoLogin,
        user,
    ]);
    return (_jsx(AuthContext.Provider, { value: providerValue, children: isInitialized ? (children) : (_jsx("div", { className: 'w-full h-full flex items-center justify-center flex-1', children: _jsx(Spinner, { size: 'xl' }) })) }));
};
